<template>
  <div>
    <div class="row">
      <div class="col-xl-5">
        <div
          class="card card-custom bgi-no-repeat gutter-b"
          style="
            background-position: right top;
            background-size: 30% auto;
            background-image: url('/media/svg/shapes/abstract-1.svg');
          "
        >
          <div class="card-body">
            <span class="svg-icon svg-icon-3x svg-icon-primary">
              <inline-svg src="media/svg/icons/Communication/Group.svg" />
            </span>
            <span
              class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block"
              >{{ totalWorkers }}</span
            >
            <span class="font-weight-bold text-muted font-size-sm">{{
              $t("GENERAL.TOTAL_WORKERS")
            }}</span>
          </div>
        </div>
        <div
          class="card card-custom bgi-no-repeat gutter-b"
          style="
            background-position: right top;
            background-size: 30% auto;
            background-image: url('/media/svg/shapes/abstract-1.svg');
          "
        >
          <div class="card-body">
            <span class="svg-icon svg-icon-3x svg-icon-primary">
              <inline-svg src="media/svg/icons/Tools/Hummer.svg" />
            </span>
            <span
              class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block"
              >{{ totalConstructions }}</span
            >
            <span class="font-weight-bold text-muted font-size-sm">{{
              $t("GENERAL.TOTAL_CONSTRUCTIONSITES")
            }}</span>
          </div>
        </div>
      </div>
      <div class="col-xl-7">
        <div
          v-if="isWorkersLoaded && workers.length > 0"
          class="card card-custom gutter-b"
        >
          <!--begin::Body-->
          <div class="card-body pt-5 pb-5">
            <!--begin::Table-->
            <div class="table-responsive">
              <table class="table table-borderless mb-0">
                <tbody>
                  <tr v-for="(worker, key) in workers" :key="key">
                    <td class="w-40px align-middle pb-6 pl-0 pr-2">
                      <!--begin::Symbol-->
                      <div class="symbol symbol-40 symbol-light-primary">
                        <span class="symbol-label">
                          <span class="svg-icon svg-icon-lg svg-icon-primary">
                            <inline-svg
                              src="media/svg/icons/General/User.svg"
                            />
                          </span>
                        </span>
                      </div>
                      <!--end::Symbol-->
                    </td>
                    <td
                      class="font-size-lg font-weight-bolder text-dark-75 align-middle w-150px pb-6"
                    >
                      {{ worker.firstName }} {{ worker.lastName }}
                    </td>
                    <td
                      class="font-weight-bold text-muted text-right align-middle pb-6"
                    >
                      <a
                        class="text-muted font-weight-bold text-hover-primary"
                        :href="'mailto:' + worker.email"
                        >{{ worker.email }}</a
                      >
                    </td>
                    <td
                      class="font-weight-bold text-muted text-right align-middle pb-6"
                    >
                      <a
                        class="text-muted font-weight-bold text-hover-primary"
                        :href="'tel:' + worker.mob"
                        >{{ worker.mob }}</a
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--end::Table-->
          </div>
          <!--end::Body-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "Dashboard",
  data() {
    return {
      workers: [],
      isWorkersLoaded: false,
      totalWorkers: null,
      totalConstructions: null,
    };
  },
  mounted() {
    ApiService.get(
      "workers?properties[]=firstName&properties[]=lastName&properties[]=email&properties[]=mob&itemsPerPage=300"
    ).then(({ data }) => {
      this.workers = data["hydra:member"];
      this.totalWorkers = data["hydra:totalItems"];
      this.isWorkersLoaded = true;
    });
    ApiService.get("constructions?itemsPerPage=1").then(({ data }) => {
      this.totalConstructions = data["hydra:totalItems"];
    });
  },
  methods: {},
};
</script>
